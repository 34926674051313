import terminalsApi from '@/api/terminals'

const state = {
  terminals: [],
}

const mutations = {
  SET_TERMINALS: (state, terminals) => state.terminals = terminals,
}

const actions = {
  fetchTerminals: ({ commit }) => terminalsApi.getList().then(r => commit('SET_TERMINALS', r.data)),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}