import parkingApi from '@/api/parking'

const state = {
  parking: {
    name: '',
    canOpenEntry: false,
    canOpenExit: false,
  }
}

const mutations = {
  SET_PARKING: (state, parking) => state.parking = parking
}

const actions = {
  fetchParking({ commit }) {
    return parkingApi.get().then(r => {
      commit('SET_PARKING', r.data)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}