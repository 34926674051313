import qrCodesApi from '@/api/qrCodes'

const state = {
  qrCodes: [],
  selected: [],
  merchantId: null,
}

const mutations = {
  SET_QR_CODES: (state, qrCodes) => state.qrCodes = qrCodes,
  SET_MERCHANT_ID: (state, merchantId) => state.merchantId = merchantId,
  SELECT_QR_CODES: (state, qrCodes) => state.selected = qrCodes,
}

const actions = {
  fetchQrCodes: ({ commit }, merchantId) => {
    commit('SET_MERCHANT_ID', merchantId)
    return qrCodesApi.getList(merchantId).then(r => commit('SET_QR_CODES', r.data))
  },
  addQrCodes: ({ state, dispatch }, count) => {
    const args = { merchantId: state.merchantId, count }
    return qrCodesApi.add(args).then(() => {
      dispatch('merchants/fetchMerchants', null, { root: true })
      dispatch('fetchQrCodes', state.merchantId)
    })
  },
  selectQrCodes: ({ commit }, qrCodes) => commit('SELECT_QR_CODES', qrCodes),
  exportToZip: ({ state, commit, dispatch }, ids) => qrCodesApi.exportToZip(ids).then(r => {
    const url = window.URL.createObjectURL(new Blob([r.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'test.zip');
    document.body.appendChild(link);
    link.click();

    commit('SELECT_QR_CODES', [])
    return dispatch('fetchQrCodes', state.merchantId)
  }),
}

export default { namespaced: true, state, mutations, actions }