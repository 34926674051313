import passesApi from '@/api/passes'

const state = {
  passes: [],
  search: '',
}

const getters = {
  filteredPasses: state => state.passes.filter(p => {
    if (!state.search) true
    return (p.fullName != null && p.fullName.toUpperCase().indexOf(state.search) > -1) ||
      (p.company != null && p.company.toUpperCase().indexOf(state.search) > -1) ||
      (p.plate != null && p.plate.toUpperCase().indexOf(state.search) > -1)
  })
}

const mutations = {
  SET_PASSES: (state, passes) => state.passes = passes,
  SET_SEARCH_VARIABLE: (state, searchVar) => state.search = searchVar,
}

const actions = {
  fetchPasses: ({ commit }) => passesApi.getList().then(r => commit('SET_PASSES', r.data)),
  addPass: ({ dispatch }, pass) => passesApi.add(pass).then(() => dispatch('fetchPasses')),
  editPass: ({ dispatch }, pass) => passesApi.edit(pass).then(() => dispatch('fetchPasses')),
  deletePass: ({ dispatch }, pass) => passesApi.delete(pass).then(() => dispatch('fetchPasses')),
  downloadCsv: () => passesApi.downloadCsv().then(r => {
    const url = window.URL.createObjectURL(new Blob([r.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'пропуски.csv');
    document.body.appendChild(link);
    link.click();
  }),
  filterPasses: ({ commit }, searchVar) => { commit("SET_SEARCH_VARIABLE", searchVar) },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}