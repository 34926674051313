import axios from 'axios'

export default {
  getList: () => axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/merchants`),
  get: (id) => axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/merchants/${id}`),
  add: (merchant) => axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/merchants`, merchant),
  edit: (merchant) => axios.put(`${process.env.VUE_APP_API_BASE_URL}/api/merchants`, merchant),
  delete: (id) => axios.delete(`${process.env.VUE_APP_API_BASE_URL}/api/merchants/${id}`),
  getListCars: (id) => axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/merchants/${id}/cars`),
  addCar: (car) => axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/merchants/create-car`, car),
  deleteCar: (id) => axios.delete(`${process.env.VUE_APP_API_BASE_URL}/api/merchants/delete-car/${id}`),  
}