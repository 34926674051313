export default {
  namespaced: false,

  state: {
    show: false,
    message: '',
    color: ''
  },

  mutations: {
    SET_DATA(state, payload) {
      state.message = payload.message || "Произошла ошибка"
      state.color = payload.type || "default"
      state.show = true
    },
    CLEAR_DATA(state) {
      state.show = false
      state.message = ''
      state.color = ''
    }
  },

  actions: {
    showAlert({ commit }, payload) {
      commit('SET_DATA', payload || {})
    },
    closeAlert({ commit }) {
      commit('CLEAR_DATA')
    }
  }
}