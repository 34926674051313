import merchantsApi from '@/api/merchants'

const state = {
  merchants: [],
  cars: [],
  search: '',
}

const getters = {
  filteredMerchants: state => state.merchants.filter(m => {
    if (!state.search) true
    return (m.phoneNumber != null && m.phoneNumber.toUpperCase().indexOf(state.search) > -1) ||
      (m.plates != null && m.plates.toUpperCase().indexOf(state.search) > -1)
  })
}

const mutations = {
  SET_MERCHANTS: (state, merchants) => state.merchants = merchants,
  SET_MERCHANT_CARS: (state, cars) => state.cars = cars,
  SET_SEARCH_VARIABLE: (state, searchVar) => state.search = searchVar,
}

const actions = {
  fetchMerchants: ({ commit }) => merchantsApi.getList().then(r => commit('SET_MERCHANTS', r.data)),
  getMerchant: (_, id) => merchantsApi.get(id),
  addMerchant: ({ dispatch }, merchant) => merchantsApi.add(merchant).then(() => dispatch('fetchMerchants')),
  editMerchant: ({ dispatch }, merchant) => merchantsApi.edit(merchant).then(() => dispatch('fetchMerchants')),
  deleteMerchant: ({ dispatch }, id) => merchantsApi.delete(id).then(() => dispatch('fetchMerchants')),
  fetchCars: ({ commit }, id) => merchantsApi.getListCars(id).then(r => commit('SET_MERCHANT_CARS', r.data)),
  addCar: ({ dispatch }, car) => merchantsApi.addCar(car).then(() => dispatch('fetchCars', car.merchantId)),
  deleteCar: ({ dispatch }, car) => merchantsApi.deleteCar(car.id).then(() => dispatch('fetchCars', car.merchantId)),
  filterMerchants: ({ commit }, searchVar) => { commit("SET_SEARCH_VARIABLE", searchVar) },
}

export default { namespaced: true, state, getters, mutations, actions }